
import { useStore } from "vuex";
import {
  computed,
  ComputedRef,
  defineComponent,
  reactive,
  ref,
  watch
} from "@vue/runtime-core";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import QuestionsTable from "@/components/Table/ts/instances/questions/QuestionsTable.vue";
import { questionSetup } from "@/views/interfaces/questionsInterfaces";
import DeleteBrandDialog from "@/components/ui/Modal/Dialog/instances/DeleteBrandDialog.vue";
import { ICategories } from "@/api/interfaces/categories/ICategories";
import { useUserTable } from "@/hooks/tables/modules/usersTable/usersTable";
import { IQuestions } from "@/api/interfaces/questions/IQuestions";
import { useFaq } from "@/hooks/faq/useFaq";
import { IBrand } from "@/store/interfaces/suported-device/IBrand";
import { categoriesData } from "@/hooks/faq/useFaq";
import CreateQuestionDialog from "@/components/ui/Modal/Dialog/instances/CreateQuestionDialog.vue";
import { sortOrders } from "@/enums/main/sortOrders";

export default defineComponent({
  name: "Questions",
  components: {
    DeleteBrandDialog,
    CreateQuestionDialog,
    QuestionsTable
  },

  setup(): questionSetup {
    const store = useStore();
    const viewConfiguration: ITableViewConfiguration = reactive({
      page: 1,
      perPage: 10
    });
    const categories: ComputedRef<ICategories[]> = computed(
      () => store.getters.categoriesList
    );
    let selectedTab: { id: number | null } = reactive({
      id: categories.value?.[0]?.id || null
    });
    const options = {
      viewConfiguration,
      selectedTab
    };
    const {
      requestQuestionHandler,
      requestCategoriesHandler,
      changeViewConfigurationHandler,
      categoriesToSelect,
      editQuestion,
      addQuestion,
      removeQuestion
    } = useFaq(options);

    requestCategoriesHandler();

    watch(
      selectedTab,
      () => {
        if (selectedTab.id) {
          requestQuestionHandler();
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    watch(
      viewConfiguration,
      () => {
        if (selectedTab.id) {
          requestQuestionHandler();
        }
      },
      {
        immediate: true
      }
    );
    let selectMenu = ref(categoriesToSelect(categories.value));

    watch(
      categories,
      updatedBrands => {
        if (!selectedTab.id) {
          selectedTab.id = updatedBrands[0]?.id;
        }
        selectMenu.value = categoriesToSelect(updatedBrands);
      },
      {
        immediate: true,
        deep: true
      }
    );

    function selectCategory(id: number): void {
      selectMenu.value.forEach(
        (category: categoriesData) => (category.selected = category.id === id)
      );
    }

    return {
      ...useUserTable(),
      viewConfiguration,
      questionsList: computed(() => store.getters.questionsList),
      categoriesList: computed(() => store.getters.categoriesList),
      totalQuestionCount: computed(() => store.getters.totalQuestions),
      requestQuestionHandler,
      selectCategory,
      editQuestion,
      addQuestion,
      removeQuestion,
      selectMenu,
      selectedTab,
      changeViewConfigurationHandler,
      changeTabHandler(tab: IBrand): void {
        if (tab?.id) {
          selectedTab.id = tab.id;
          viewConfiguration.page = 1;
        }
      }
    };
  },

  data() {
    return {
      removeTitle: "Delete question",
      editText: "",
      editId: 0,
      createQuestionDialogOpened: false,
      deleteQuestionDialogOpened: false,
      editData: {} as IQuestions | undefined,
      isLoading: false,
      sortConfig: {
        byName: { order: sortOrders.turnOff, keyName: "title" }
      }
    };
  },

  computed: {
    emptyText(): string {
      return `No question found!`;
    },
    modalTitle(): string {
      return this.editData?.id ? "Edit question" : "Add question";
    }
  },

  methods: {
    async removingQuestion(id: number) {
      this.deleteQuestionDialogOpened = true;
      this.editData = this.questionsList.find(
        questionItem => questionItem.id == id
      );
    },
    cleanData() {
      this.deleteQuestionDialogOpened = false;
      this.createQuestionDialogOpened = false;
      this.editData = {} as IQuestions | undefined;
    },
    async editAction(data: IQuestions) {
      this.isLoading = true;
      let success;
      if (data.id) {
        success = await this.editQuestion(data);
      } else {
        success = await this.addQuestion(data);
      }
      this.isLoading = false;
      if (success) {
        this.cleanData();
        this.requestQuestionHandler();
      }
    },
    openForm() {
      this.selectCategory(this.selectedTab.id);
      this.createQuestionDialogOpened = true;
    },
    editingQuestion(id: number) {
      this.editData = this.questionsList.find(
        questionItem => questionItem.id == id
      );
      this.createQuestionDialogOpened = true;
    },
    onDeleteCancel() {
      this.editData = {} as IQuestions;
      this.deleteQuestionDialogOpened = false;
    },

    async onDeleteConfirm(id: number): Promise<void> {
      this.isLoading = true;
      const success = await this.removeQuestion(id);
      if (success) {
        this.cleanData();
        await this.requestQuestionHandler();
      }
      this.isLoading = false;
    }
  }
});
